<template>
  <section class="expandable-transaction-table-component">
    <bg-skeleton
      v-if="loading"
      width="100%"
      height="50px"
      data-testid="expenditure-loader"
    />
    <div v-else class="expandable-table-container">
      <table :class="['expandable-table', { empty: !data.length }]">
        <thead class="main-thead">
          <tr>
            <th></th>
            <th>ID</th>
            <th>Tipe Pengajuan Cash Out</th>
            <th>Nama Properti</th>
            <th>Nama Pengeluaran</th>
            <th>Total Pengeluaran</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>

        <template v-if="data.length">
          <tbody
            class="one-row"
            v-for="(item, index) in data"
            :key="`expense-${item.id}`"
          >
            <tr class="main-item">
              <td>
                <bg-button
                  variant="tertiary"
                  size="sm"
                  @click="expandContent(index)"
                  data-testid="expand-toggle"
                >
                  <bg-icon
                    name="chevron-down"
                    size="sm"
                    :class="{ expanded: item.expanded }"
                  />
                </bg-button>
              </td>
              <td>PPO{{ item.id }}</td>
              <td>{{ item.cash_out_type }}</td>
              <td>{{ item.property_name }}</td>
              <td>
                <ol>
                  <li
                    v-for="(detail, id) in item.detail_expenses"
                    :key="`expense-list-${item.id}-${id}`"
                  >
                    {{ detail.name }}
                  </li>
                </ol>
              </td>
              <td>
                <p class="mb-4">{{ rupiahFormatter(item.total_expense) }}</p>
                <bg-link
                  :href="item.attachment_url"
                  target="_blank"
                  rel="noopener"
                  class="blue-link"
                  >Lihat lampiran</bg-link
                >
              </td>
              <td>
                <div class="status-table">
                  <div class="row">
                    <div class="column">Manager</div>
                    <div class="column">:</div>
                    <div class="column">
                      <bg-label-rainbow
                        :color="statusLabel(item.approval.manager.status).color"
                        >{{
                          statusLabel(item.approval.manager.status).text
                        }}</bg-label-rainbow
                      >
                      {{ item.approval.manager.name || '' }}
                      {{
                        item.approval.manager.updated_at
                          ? '- ' +
                            expenseDateFormatter(
                              item.approval.manager.updated_at
                            )
                          : ''
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="column">Finance</div>
                    <div class="column">:</div>
                    <div class="column">
                      <bg-label-rainbow
                        :color="statusLabel(item.approval.finance.status).color"
                        >{{
                          statusLabel(item.approval.finance.status).text
                        }}</bg-label-rainbow
                      >
                      {{ item.approval.finance.name || '' }}
                      {{
                        item.approval.finance.updated_at
                          ? '- ' +
                            expenseDateFormatter(
                              item.approval.finance.updated_at
                            )
                          : ''
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="column">Disbursement</div>
                    <div class="column">:</div>
                    <div class="column">
                      {{
                        item.disbursed_at
                          ? expenseDateFormatter(item.disbursed_at, true)
                          : '-'
                      }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <table-action-menu
                  v-if="
                    accessOwnerExpenditureActions ||
                    accessConfirmManagerOwnerExpenditure ||
                    accessConfirmFinanceOwnerExpenditure
                  "
                  :disabled="
                    !accessForAdmin &&
                    ((accessConfirmManagerOwnerExpenditure &&
                      item.approval.manager.status === 'confirmed') ||
                      (accessConfirmFinanceOwnerExpenditure &&
                        (item.approval.manager.status === 'unconfirmed' ||
                          item.approval.finance.status === 'confirmed')))
                  "
                  data-testid="table-action-menu"
                >
                  <bg-list-item
                    v-if="accessOwnerExpenditureActions"
                    :clickable="
                      item.approval.manager.status !== 'confirmed'
                    "
                    data-testid="edit-expenditure"
                    @click="handleTransactionAction('edit', item.id)"
                  >
                    Ubah
                  </bg-list-item>
                  <bg-list-item
                    v-if="
                      item.approval.manager.status !== 'confirmed' &&
                      accessConfirmManagerOwnerExpenditure
                    "
                    clickable
                    data-testid="manager-confirm"
                    @click="
                      handleTransactionAction('confirmation', {
                        role: 'manager',
                        id: item.id,
                      })
                    "
                  >
                    <span class="confirmation-color">Konfirmasi</span>
                  </bg-list-item>
                  <bg-list-item
                    v-if="
                      item.approval.manager.status === 'confirmed' &&
                      item.approval.finance.status !== 'confirmed' &&
                      accessConfirmFinanceOwnerExpenditure
                    "
                    clickable
                    data-testid="finance-confirm"
                    @click="
                      handleTransactionAction('confirmation', {
                        role: 'finance',
                        id: item.id,
                      })
                    "
                  >
                    <span class="confirmation-color">Konfirmasi</span>
                  </bg-list-item>
                  <bg-list-item
                    v-if="accessOwnerExpenditureActions"
                    :clickable="item.approval.manager.status !== 'confirmed'"
                    @click="handleTransactionAction('delete', { id: item.id })"
                  >
                    Hapus
                  </bg-list-item>
                </table-action-menu>
              </td>
            </tr>
            <tr :class="['sub-item', { 'is-open': item.expanded }]">
              <td></td>
              <td colspan="6">
                <bg-card class="mt-24 mb-24">
                  <bg-grid>
                    <bg-grid-item :col="3">
                      <p class="mb-4">
                        <strong>Kota</strong>
                      </p>
                      <p>{{ item.city }}</p>
                    </bg-grid-item>
                    <bg-grid-item :col="3">
                      <p class="mb-4">
                        <strong>Sisa Kontrak Kerja Sama</strong>
                      </p>
                      <p>{{ item.remaining_contract_duration }} Bulan</p>
                    </bg-grid-item>
                    <bg-grid-item :col="3">
                      <p class="mb-4">
                        <strong>Vendor</strong>
                      </p>
                      <p>{{ item.vendor.name }}</p>
                    </bg-grid-item>
                    <bg-grid-item :col="3">
                      <p class="mb-4">
                        <strong>No. Invoice Biaya</strong>
                      </p>
                      <p>{{ item.invoice_number }}</p>
                    </bg-grid-item>
                    <bg-grid-item :col="3">
                      <p class="mb-4">
                        <strong>Nama Bank</strong>
                      </p>
                      <p>{{ item.vendor.bank_name }}</p>
                    </bg-grid-item>
                    <bg-grid-item :col="3">
                      <p class="mb-4">
                        <strong>Nomor Rekening</strong>
                      </p>
                      <p>{{ item.vendor.account_number }}</p>
                    </bg-grid-item>
                    <bg-grid-item :col="3">
                      <p class="mb-4">
                        <strong>Nama Pemilik Rekening</strong>
                      </p>
                      <p>{{ item.vendor.account_name }}</p>
                    </bg-grid-item>
                    <bg-grid-item :col="3">
                      <p class="mb-4">
                        <strong>Dibuat oleh</strong>
                      </p>
                      <p>
                        {{ item.created_by }}.
                        {{ expenseDateFormatter(item.created_at) }}
                      </p>
                    </bg-grid-item>
                  </bg-grid>
                  <bg-divider class="mt-24 mb-24" />
                  <table class="detail-table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Kategori Pengeluaran</th>
                        <th>Nama Pengeluaran</th>
                        <th>Kuantitas</th>
                        <th>Nominal Pengeluaran</th>
                        <th>Status Persediaan</th>
                        <th>Jenis Produk</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(detail, id) in item.detail_expenses"
                        :key="`detail-expense-row-${item.id}-${id}`"
                      >
                        <td>{{ id + 1 }}.</td>
                        <td>
                          {{ detail.category }}
                        </td>
                        <td>
                          {{ detail.name }}
                        </td>
                        <td>
                          {{ detail.quantity }}
                        </td>
                        <td>
                          {{ rupiahFormatter(detail.amount) }}
                        </td>
                        <td>
                          {{
                            detail.is_stock_available ? 'Stock' : 'Non Stock'
                          }}
                        </td>
                        <td>
                          {{ productTypeAcronym(detail.product) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </bg-card>
              </td>
              <td></td>
            </tr>
          </tbody>
        </template>

        <template v-else>
          <tbody>
            <tr>
              <td colspan="8">
                <div class="not-found pt-24 text-muted">
                  <template v-if="fromFiltering">
                    Data yang dicari tidak ditemukan
                  </template>
                  <template v-else>
                    Tidak ada data yang ditampilkan
                  </template>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </section>
</template>

<script>
import { rupiahFormatter, dateFormatterToDisplay } from 'Utils/formatter';

import {
  BgGrid,
  BgGridItem,
  BgCard,
  BgIcon,
  BgDivider,
  BgLink,
  BgLabelRainbow,
  BgListItem,
  BgButton,
  BgSkeleton,
} from 'bangul-vue';

export default {
  name: 'ExpandableTransactionTable',

  components: {
    BgGrid,
    BgGridItem,
    BgCard,
    BgIcon,
    BgDivider,
    BgLink,
    BgLabelRainbow,
    BgListItem,
    BgButton,
    BgSkeleton,
    TableActionMenu: () => import('@admin_molecules/TableActionMenu'),
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    fromFiltering: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    accessOwnerExpenditureActions() {
      return this.$store.getters.xCheckUserPermission('add-owner-expenditure');
    },
    accessConfirmManagerOwnerExpenditure() {
      return this.$store.getters.xCheckUserPermission(
        'confirm-manager-owner-expenditure'
      );
    },
    accessConfirmFinanceOwnerExpenditure() {
      return this.$store.getters.xCheckUserPermission(
        'confirm-finance-owner-expenditure'
      );
    },
    accessForAdmin() {
      return (
        this.accessConfirmManagerOwnerExpenditure &&
        this.accessConfirmFinanceOwnerExpenditure
      );
    },
  },

  methods: {
    rupiahFormatter,

    expandContent(index) {
      this.$emit('expand-content', index);
    },

    statusLabel(value) {
      const text = {
        confirmed: 'Dikonfirmasi',
        rejected: 'Ditolak',
        unconfirmed: 'Menunggu Konfirmasi',
      };

      const color = {
        confirmed: 'green',
        rejected: 'red',
        unconfirmed: 'white',
      };

      return {
        text: text[value],
        color: color[value],
      };
    },

    expenseDateFormatter(value, simple = false) {
      return simple
        ? dateFormatterToDisplay(value)
        : dateFormatterToDisplay(value, 'DD MMM YYYY, HH:mm');
    },

    productTypeAcronym(value) {
      const regex = /\(([^)]+)\)/;
      return regex.exec(value)[1];
    },

    handleTransactionAction(type, data) {
      if (type === 'edit') {
        this.$router.push(`/other-transaction/expenditure/edit/${data}`);
      } else {
        const payload = {
          ...data,
          type,
        };

        this.$emit('handle-action', payload);
      }
    },
  },
};
</script>

<style lang="scss" src="./ExpandableTransactionTable.scss" scoped />
